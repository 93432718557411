.Bookmark {
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    max-width: 400px;
    padding: 10px 20px;

    .BookmarkHeading {
        font-weight: bold;
    }
}
