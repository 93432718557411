.Bookmark {
  background-color: #fff;
  border-radius: 3px;
  max-width: 400px;
  padding: 10px 20px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #0000000a;
}

.Bookmark .BookmarkHeading {
  font-weight: bold;
}

.CompanyPassword {
  background-color: #fff;
  border-radius: 3px;
  max-width: 400px;
  padding: 10px 20px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #0000000a;
}

.CompanyPassword .heading {
  font-weight: bold;
}

.CompanyPassword .orange {
  background-color: #ffdab3;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: .5rem;
}

.CompanyPassword .blue {
  background-color: #afc9e9;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: .5rem;
}

.CompanyPassword .sms-buttom .spinner {
  margin-left: 1rem;
}

.CompanyPassword .sms-buttom .button {
  color: #fff;
  text-transform: "uppercase";
  background-color: #3878c7;
  border: none;
  border-radius: 2px;
  outline: none;
  margin-bottom: .7rem;
  padding: 5px 10px;
  font-weight: bold;
  transition: all .1s;
  box-shadow: 0 2px 4px #00000029, 0 0 0 1px #00000014;
}

.CompanyPassword .sms-buttom .button:hover {
  background-color: #6093d2;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #00000014;
}

.CompanyPassword .sms-buttom .button:active {
  outline: 2px;
}

.CompanyPassword .sms-buttom .button:focus {
  background-color: #2d609f;
  box-shadow: 0 0 0 3px #afc9e9;
}

.CompanyPassword .sms-buttom .button:disabled {
  background-color: #afc9e9;
}

.CompanyPassword .sms-buttom .response {
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.CompanyPassword .sms-buttom .response .response-message {
  margin-left: 1rem;
}

.CompanyPassword .sms-buttom .error {
  color: #a3292f;
}

.CompanyPassword .sms-buttom .success {
  color: #1c634c;
}

.LinkPage {
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  width: 400px;
  min-height: 400px;
  padding: 5px 10px;
  display: flex;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #0000000a;
}

.LinkPage .LinkPageLoader {
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.LinkPage .LinkPageLoader > span {
  margin-left: 5px;
}

.LinkPage .LinkPageMessage {
  resize: none;
}

.LinkPage .SendOptions {
  margin-top: 20px;
}

.LinkPage .SendOptions .SendOptionsForm {
  padding: 10px;
}

.LinkPage .SendOptions .SendOptionsForm .TBMessage .SendOptionsOption {
  margin-bottom: 0;
}

.LinkPage .SendOptions .SendOptionsForm .SendOptionsOption {
  display: flex;
}

.LinkPage .SendOptions .SendOptionsForm .SendOptionsOption .SendOptionsOptionInput {
  box-shadow: none;
  margin-right: 5px;
  width: auto !important;
}

.LinkPage .SendOptions .SendOptionsForm .SendOptionsInfoText {
  font-size: 10px;
  font-style: italic;
}

.LinkPage .SendButtonWrapper {
  justify-content: flex-end;
  display: flex;
}

.LinkPage .SendButtonWrapper .SendSpinner {
  margin-right: 5px;
}

.LinkPage .SendButtonWrapper .SendLinkButton {
  color: #fff;
  text-transform: "uppercase";
  background-color: #3878c7;
  border: none;
  border-radius: 2px;
  outline: none;
  margin-bottom: 20px;
  padding: 5px 10px;
  font-weight: bold;
  transition: all .1s;
  box-shadow: 0 2px 4px #00000029, 0 0 0 1px #00000014;
}

.LinkPage .SendButtonWrapper .SendLinkButton:hover {
  background-color: #6093d2;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #00000014;
}

.LinkPage .SendButtonWrapper .SendLinkButton:active {
  outline: 2px;
}

.LinkPage .SendButtonWrapper .SendLinkButton:focus {
  background-color: #2d609f;
  box-shadow: 0 0 0 3px #afc9e9;
}

.LinkPage .SendButtonWrapper .SendLinkButton:disabled {
  background-color: #afc9e9;
}

.LinkPage .SendButtonWrapper .SendSucceed {
  color: #2d9f79;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.LinkPage .SendButtonWrapper .SendSucceed .SendSucceedMessage {
  margin-left: 5px;
}

.ErrorMessage {
  color: #cd333b;
  font-weight: bold;
}

.SystemMenuItem {
  display: flex;
}

.SystemMenuItem:hover {
  background-color: #0000000f;
}

.SystemMenuItem > a {
  flex: 1;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.SystemMenuItem > a span {
  flex: 1;
}

.SystemMenuItem > a:hover {
  text-decoration: none;
}

.SystemMenuItem .SystemMenuLink {
  color: #3878c7;
  font-size: 12px;
}

.SystemMenu {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #0000000a;
}

.SystemMenu hr {
  margin: 10px 0;
}

.SysMenu {
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

.SysMenu .SysMenuButton {
  color: #00000040;
  background-color: #0000;
  border: none;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #0000000a;
}

.SysMenu .SysMenuButton:hover {
  color: #0009;
  background-color: #fff;
}

.SysMenu .SysMenuButton:active {
  background-color: #ebebeb;
  box-shadow: inset 0 3px 5px #00000021;
}

.SysMenu .SystemMenuWrapper {
  margin-bottom: 5px;
}

.SysMenu .SystemMenuWrapper .SystemMenuContent {
  background-color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 1px #0000000a;
}
/*# sourceMappingURL=index.css.map */
