@import '../less/colors.less';

.LinkPage {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    min-height: 400px;
    width: 400px;
    padding: 5px 10px;

    .LinkPageLoader {
        align-items: center;
        display: flex;
        justify-content: center;
        flex: 1 auto;

        > span {
            margin-left: 5px;
        }
    }

    .LinkPageMessage {
        resize: none;
    }

    .SendOptions {
        margin-top: 20px;

        .SendOptionsForm {
            padding: 10px;

            .TBMessage {
                .SendOptionsOption {
                    margin-bottom: 0;
                }
            }

            .SendOptionsOption {
                display: flex;

                .SendOptionsOptionInput {
                    box-shadow: none;
                    margin-right: 5px;
                    width: auto !important;
                }
            }

            .SendOptionsInfoText {
                font-style: italic;
                font-size: 10px;
            }
        }
    }

    .SendButtonWrapper {
        display: flex;
        justify-content: flex-end;

        .SendSpinner {
            margin-right: 5px;
        }

        .SendLinkButton {
            background-color: #3878c7;
            border: none;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
            color: white;
            font-weight: bold;
            margin-bottom: 20px;
            outline: none;
            padding: 5px 10px;
            text-transform: 'uppercase';
            transition: 0.1s all ease;

            &:hover {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
                background-color: lighten(#3878c7, 10%);
            }

            &:active {
                outline: 2px;
            }

            &:focus {
                background-color: darken(#3878c7, 10%);
                box-shadow: 0 0 0 3px lighten(#3878c7, 30%);
            }

            &:disabled {
                background-color: lighten(#3878c7, 30%);
            }
        }

        .SendSucceed {
            color: @green-primary;
            display: flex;
            align-items: center;
            margin-right: 10px;

            .SendSucceedMessage {
                margin-left: 5px;
            }
        }
    }
}

.ErrorMessage {
    color: @red-primary;
    font-weight: bold;
}
