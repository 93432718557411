.SysMenu {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    position: fixed;
    right: 0;

    .SysMenuButton {
        background-color: transparent;
        border: none;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.25);
        height: 30px;
        width: 30px;

        &:hover {
            background-color: #ffffff;
            color: rgba(0, 0, 0, 0.6);
        }

        &:active {
            background-color: #ebebeb;
            box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
        }
    }

    .SystemMenuWrapper {
        margin-bottom: 5px;

        .SystemMenuContent {
            background-color: #ffffff;
            border-radius: 3px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.04);
            padding: 5px 10px;
        }
    }
}
