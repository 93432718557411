@import '../less/colors.less';

.CompanyPassword {
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    max-width: 400px;
    padding: 10px 20px;

    .heading {
        font-weight: bold;
    }

    .orange {
        background-color: @yellow-light-medium;
        padding: 0.5rem;
        border-radius: 5px;
        margin-bottom: 1rem;
    }

    .blue {
        background-color: @blue-light-medium;
        padding: 0.5rem;
        border-radius: 5px;
        margin-bottom: 1rem;
    }

    .sms-buttom {
        .spinner {
            margin-left: 1rem;
        }

        .button {
            background-color: @blue-primary;
            border: none;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
            color: white;
            font-weight: bold;
            margin-bottom: 0.7rem;
            outline: none;
            padding: 5px 10px;
            text-transform: 'uppercase';
            transition: 0.1s all ease;

            &:hover {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
                background-color: lighten(#3878c7, 10%);
            }

            &:active {
                outline: 2px;
            }

            &:focus {
                background-color: darken(#3878c7, 10%);
                box-shadow: 0 0 0 3px lighten(#3878c7, 30%);
            }

            &:disabled {
                background-color: lighten(#3878c7, 30%);
            }
        }

        .response {
            display: flex;
            align-items: center;
            margin-right: 10px;

            .response-message {
                margin-left: 1rem;
            }
        }

        .error {
            color: @red-dark;
        }

        .success {
            color: @green-dark;
        }
    }
}
