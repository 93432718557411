@import '../less/colors.less';
@import '../less/sizes.less';

.SystemMenuItem {
    display: flex;

    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }

    > a {
        align-items: center;
        display: flex;
        flex: 1;
        padding: 5px 10px;

        span {
            flex: 1;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .SystemMenuLink {
        color: @blue-primary;
        font-size: @tekst-s;
    }
}
